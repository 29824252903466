import React, {ChangeEvent, useEffect, useState} from 'react';
import {SubmitHandler, useForm} from "react-hook-form";
import style from './Info.module.css';

type  StateType = {
    binary?: string
    dir?: string
    moniker?: string
    name?: string
    network?: string
    identity?: string
    details?: string
    website?: string
    wallet?: string
    denom?: string
    fees?: string
    peers?: string
    seeds?: string
    pruning1?: string
    pruning2?: string
    pruning3?: string
    a1?: string |number
    a2?: string |number
    a3?: string |number
    a4?: string |number
    c1?: string |number
    c2?: string |number
    c3?: string |number
    c4?: string |number
    c5?: string |number
    valoper?: string
    amount?: string |number
    vote?: string |number
}

export const Info = React.memo(() => {

    const initialState = {
        binary: "",
        dir: "",
        name: "",
        moniker: "",
        network: "",
        identity: "",
        details: "",
        website: "",    
        wallet: "",
        denom: "",
        fees: "0",
        peers: "",
        seeds: "",
        pruning1: "100",
        pruning2: "0",
        pruning3: "10",
        a1: "",
        a2: "",
        a3: "",
        a4: "",
        c1: "",
        c2: "",
        c3: "",
        c4: "",
        c5: "",
        valoper: "",
        vote: "1",
        amount: "1",
    }

    const m_netwokrs: any = {
      '':{
      },
      'Aura': {         
        binary: "aurad",
        dir: "aura",
        network: "euphoria-1",
        denom: "ueaura"
      },
      'Crowd Control': {         
        binary: "Cardchain",
        dir: "Cardchain",
        network: "Cardchain",
        denom: "ubpf"
      },
      'Defund': {         
        binary: "defundd",
        dir: "defund",
        network: "defund-private-1",
        denom: "ufetf"
      },
        'Decentralized Web Services (DWS)': {         
        binary: "dewebd",
        dir: "deweb",
        network: "deweb-testnet-1",
        denom: "udws"
      },
      'Quicksilver': {
        binary: "quicksilverd",
        dir: "quicksilverd",
        network: "killerqueen-1",
        denom: "uqck"
      },
      'KYVE': {
        binary: "chaind",
        dir: "kyve",
        network: "korellia",
        denom: "tkyve"
      },
      'Lava': {
        binary: "lavad",
        dir: "lava",
        network: "lava-testnet-1",
        denom: "ulava"
      },
      'Neutron': {
        binary: "neutrond",
        dir: "neutrond",
        network: "quark-1",
        denom: "untrn"
      },
      'OKP4': {
        binary: "okp4d",
        dir: "okp4d",
        network: "okp4-nemeton-1",
        denom: "uknow"
      },
      'Paloma': {
        binary: "palomad",
        dir: "paloma",
        network: "paloma-testnet-6",
        denom: "ugrain"
      },      
      'Rebus': {
        binary: "rebusd",
        dir: "rebusd",
        network: "reb_3333-1",
        denom: "arebus"
       }, 
      'Sei': {
        binary: "seid",
        dir: "sei",
        network: "atlantic-1",
        denom: "usei"
       }, 
      'StaFiHub': {
        binary: "stafihubd",
        dir: "stafihub",
        network: "stafihub-testnet-1",
        denom: "ufis"
      },
      'Stride': {
        binary: "strided",
        dir: "stride",
        network: "STRIDE-TESTNET-2",
        denom: "ustrd"
       }, 
      'Uptick': {
          binary: "uptickd",
          dir: "uptick",
          network: "uptick_7776-1",
          denom: "auptick"
      },           
      'Bitsong - Mainnet': {
        binary: "bitsongd",
        dir: "bitsongd",
        network: "bitsong-2b",
        denom: "ubtsg"
      },
      'Canto - Mainnet': {
        binary: "cantod",
        dir: "cantod",
        network: "canto_7700-1",
        denom: "acanto"
      },
      'Chihuahua - Mainnet': {
        binary: "chihuahuad",
        dir: "chihuahua",
        network: "chihuahua-1",
        denom: "uhuahua"
      },
      'Galaxy - Mainnet': {
        binary: "galaxyd",
        dir: "galaxy",
        network: "galaxy-1",
        denom: "uglx"
      },
      'Gravity Bridge - Mainnet': {
        binary: "gravity",
        dir: "gravity",
        network: "gravity-bridge-3",
        denom: "ugraviton"
      },
      'Impact Hub - Mainnet': {
        binary: "ixod",
        dir: "ixod",
        network: "impacthub-3",
        denom: "uixo"
      },
      'Kichain - Mainnet': {
        binary: "kid",
        dir: "kid",
        network: "kichain-2",
        denom: "uxki"
      },
      'Rizon - Mainnet': {
        binary: "rizond",
        dir: "rizon",
        network: "titan-1",
        denom: "uatolo"
      },
      'Point - Mainnet': {
        binary: "pointd",
        dir: "pointd",
        network: "point_10687-1",
        denom: "apoint"
      },
    }

    const [value, setValue] = useState('');
    
    //const netwokrs = ['', 'defund', 'kyve', 'uptick'];

    const options = Object.keys(m_netwokrs).map((text, index) => {
        return <option key={index}>{text}</option>;
    });
//    console.log(Object.keys(m_netwokrs));
//    const options = m_netwokrs.map((text, index) => {
//      return <option key={index}>{text}</option>;
//    });

    const [mainState, setMainState] = useState<StateType>(initialState);
    // const [nameInput, setNameInput] = useState("");

    const onSubmit: SubmitHandler<StateType> = (data) => {
    }



    const universalSetLocalFunction = (value: keyof typeof initialState) => {
      if (mainState[value]) {
          localStorage.setItem(`${value}`, mainState[value] as string);
      }
    }

    const inputHandler = (e: ChangeEvent<HTMLInputElement>) => {
      const {name, value} = e.target;
      if (value && /^[a-zA-Z0-9\,\.\:\@\_\-\/]+$/.test(value)) {
          setMainState({
              ...mainState,
              [name]: value
          })
          // setNameInput(name)
      } else {
          setMainState({
              ...mainState,
              [name]: ""
          })
      }
    }
    const inputHandlerAll = (e: ChangeEvent<HTMLInputElement>) => {
      const {name, value} = e.target;
          setMainState({
              ...mainState,
              [name]: value
          })      
    }
    const inputHandlerNumbers = (e: ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        if (value && /^\d+$/.test(value)) {
            setMainState({
                ...mainState,
                [name]: value
            })
        } else {
            setMainState({
                ...mainState,
                [name]: ""
            })
        }
    }
    const inputHandlerReg = (e: ChangeEvent<HTMLTextAreaElement>) => {
        const {name, value} = e.target;
        if (/^[a-z0-9\,\.\:\@\_\-]*$/.test(value)) {
            setMainState({
                ...mainState,
                [name]: value
            })
        } else {
            setMainState({
                ...mainState,
                [name]: ""
            })
        }
    }

       const selectHandler = (e: ChangeEvent<HTMLSelectElement>) => {
        setValue(e.currentTarget.value)
        // if (!mainState.binary && mainState.binary === "defund" || "sei" || "uptick") {
        //     setMainState({...mainState, binary: e.currentTarget.value})
        // } else {
        //     setMainState({...mainState, [nameInput]: e.currentTarget.value})
        // }

    }

    useEffect(() => {        
      if (value) {
          setMainState(
              {...mainState,
                  binary:m_netwokrs[value].binary,
                  dir: m_netwokrs[value].dir,
                  network: m_netwokrs[value].network,
                  denom: m_netwokrs[value].denom,
              })
      }
    }, [value])

    useEffect(() => {
      for (let i = 0; i < localStorage.length; i++) {
          if (localStorage.key(i)! in initialState) {
            setMainState((prev) => ({
              ...prev,
              [localStorage.key(i) as string]: localStorage.getItem(localStorage.key(i)!)
          }))
          }
        }           
      }, []
    )

  const clear = () => {
    setValue("")
    setMainState(initialState)
    //localStorage.setItem("wallet", "")
    //localStorage.setItem("name", "")
  }
    const {register, handleSubmit, formState: {errors, isValid}} = useForm({
        mode: 'onChange'
    });
    const n0=() => {
      setMainState({
        ...mainState,
        c1:'',
        c2:'',
        c3:'',
        c4:'',
        c5:'',
        a1:'',
        a2:'',
        a3:'',
        a4:''     
      })
    }
    
    const n1=() => {
      setMainState({
        ...mainState,
        c1:26759,
        c2:26758,
        c3:6161,
        c4:26757,
        c5:26660,
        a1:1418,
        a2:8181,
        a3:9191,
        a4:9192      
      })
    }
    const n2=() => {
      setMainState({
        ...mainState,
        c1:26860,
        c2:26859,
        c3:6262,
        c4:26858,
        c5:26862,
        a1:1519,
        a2:8282,
        a3:9292,
        a4:9293        
      })
    }
    const n3=() => {
      setMainState({
        ...mainState,
        c1:26961,
        c2:26960,
        c3:6363,
        c4:26959,
        c5:26963,
        a1:1620,
        a2:8383,
        a3:9393,
        a4:9394        
      })
    }
    const n4=() => {
      setMainState({
        ...mainState,
        c1:27062,
        c2:27061,
        c3:6464,
        c4:27060,
        c5:27064,
        a1:1721,
        a2:8484,
        a3:9494,
        a4:9495         
      })
    }
    const n5=() => {
      setMainState({
        ...mainState,
        c1:27163,
        c2:27162,
        c3:6565,
        c4:27161,
        c5:27165,
        a1:1822,
        a2:8585,
        a3:9595,
        a4:9596     
      }) 
    }
    const n6=() => {
      setMainState({
        ...mainState,
        c1:27264,
        c2:27263,
        c3:6666,
        c4:27262,
        c5:27266,
        a1:1923,
        a2:8686,
        a3:9696,
        a4:9697         
      })
    }
    const n7=() => {
      setMainState({
        ...mainState,
        c1:27365,
        c2:27364,
        c3:6767,
        c4:27363,
        c5:27367,
        a1:2024,
        a2:8787,
        a3:9797,
        a4:9798           
      })
    }
    const n8=() => {
      setMainState({
        ...mainState,
        c1:27466,
        c2:27465,
        c3:6868,
        c4:27464,
        c5:27468,
        a1:2125,
        a2:8888,
        a3:9898,
        a4:9899         
      })
    }
    const n9=() => {
      setMainState({
        ...mainState,
        c1:27567,
        c2:27566,
        c3:6969,
        c4:27565,
        c5:27569,
        a1:2226,
        a2:8989,
        a3:9999,
        a4:10000         
      })      
    }
    const n10=() => {
      setMainState({
        ...mainState,
        c1:26769,
        c2:26768,
        c3:6171,
        c4:26767,
        c5:26771,
        a1:1428,
        a2:8191,
        a3:9201,
        a4:9202         
      })      
    }
    const n11=() => {
      setMainState({
        ...mainState,
        c1:26880,
        c2:26879,
        c3:6282,
        c4:26878,
        c5:26882,
        a1:1539,
        a2:8302,
        a3:9312,
        a4:9313         
      })      
    }
    const n12=() => {
      setMainState({
        ...mainState,
        c1:26991,
        c2:26990,
        c3:6393,
        c4:26989,
        c5:26993,
        a1:1650,
        a2:8413,
        a3:9423,
        a4:9424         
      })      
    }
    const n13=() => {
      setMainState({
        ...mainState,
        c1:27102,
        c2:27101,
        c3:6504,
        c4:27100,
        c5:27104,
        a1:1761,
        a2:8524,
        a3:9534,
        a4:9535         
      })      
    }
    const n14=() => {
      setMainState({
        ...mainState,
        c1:27213,
        c2:27212,
        c3:6615,
        c4:27211,
        c5:27215,
        a1:1872,
        a2:8635,
        a3:9645,
        a4:9646         
      })      
    }
    const n15=() => {
      setMainState({
        ...mainState,
        c1:27324,
        c2:27323,
        c3:6726,
        c4:27322,
        c5:27326,
        a1:1983,
        a2:8746,
        a3:9756,
        a4:9757         
      })      
    }
    const n16=() => {
      setMainState({
        ...mainState,
        c1:27435,
        c2:27434,
        c3:6837,
        c4:27433,
        c5:27437,
        a1:2094,
        a2:8857,
        a3:9867,
        a4:9868         
      })      
    }
    const n17=() => {
      setMainState({
        ...mainState,
        c1:27546,
        c2:27545,
        c3:6948,
        c4:27544,
        c5:27548,
        a1:2205,
        a2:8968,
        a3:9978,
        a4:9979        
      })      
    }
    const n18=() => {
      setMainState({
        ...mainState,
        c1:27657,
        c2:27656,
        c3:7059,
        c4:27655,
        c5:27659,
        a1:2316,
        a2:9079,
        a3:10089,
        a4:10090         
      })      
    }
    return (
        <div className={style.cch_main}>
          <div className={style.row}>
          <span>templates</span>
            <select className={style.normale2} value={value} onChange={selectHandler}>
                {options}
            </select>            
            <button className={style.normale3} onClick={clear}>Clear all</button>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={style.row}>
                    <span>binary_name</span>
                    <input className={style.normale} placeholder='simd' {...register("binary", {                        
                    })} onInput={inputHandler} value={mainState.binary}/>
                    {errors.binary && <div style={{color: "red"}}>{errors.binary.message}</div>}
                </div>
                <div className={style.row}>
                    <span>dir_name</span>
                    <input className={style.normale} placeholder='simd' {...register("dir", {                        
                    })} onInput={inputHandler} value={mainState.dir}/>
                    {errors.dir && <div style={{color: "red"}}>{errors.dir.message}</div>}
                </div>
                <div className={style.row}>
                    <span>node_name</span>
                    <input className={style.normale} placeholder='node_name' {...register("name", {                        
                    })} onInput={inputHandlerAll} value={mainState.name} onBlur={() => universalSetLocalFunction("name")}/>
                    {errors.name && <div style={{color: "red"}}>{errors.name.message}</div>}
                </div>
                <div className={style.row}>
                    <span>moniker</span>
                    <input className={style.normale} placeholder='moniker' {...register("moniker", {                        
                    })} onInput={inputHandlerAll} value={mainState.moniker} onBlur={() => universalSetLocalFunction("moniker")}/>
                    {errors.moniker && <div style={{color: "red"}}>{errors.moniker.message}</div>}
                </div>
                <div className={style.row}>
                    <span>identity <a href="https://keybase.io/" target="_blank" className={style.cch_link}>🔗</a></span> 
                    <input className={style.normale} placeholder='identity' {...register("identity", {
                    })} onInput={inputHandler} onBlur={() => universalSetLocalFunction("identity")}
                           value={mainState.identity}/>
                    {errors.identity && <div style={{color: "red"}}>{errors.identity.message}</div>}
                </div>
                <div className={style.row}>
                    <span>details</span>
                    <input className={style.normale} placeholder='details' {...register("details", {                       
                    })} onInput={inputHandlerAll} onBlur={() => universalSetLocalFunction("details")}
                           value={mainState.details}/>
                    {errors.details && <div style={{color: "red"}}>{errors.details.message}</div>}
                </div>
                <div className={style.row}>
                    <span>website</span>
                    <input className={style.normale} placeholder='website' {...register("website", {                        
                    })} onInput={inputHandler} onBlur={() => universalSetLocalFunction("website")}
                           value={mainState.website}/>
                    {errors.website && <div style={{color: "red"}}>{errors.website.message}</div>}
                </div>
                <div className={style.row}>
                    <span>wallet_name</span>
                    <input className={style.normale} placeholder='my_wallet' {...register("wallet", {                        
                    })} onInput={inputHandler} value={mainState.wallet} onBlur={() => universalSetLocalFunction("wallet")}/>
                    {errors.wallet && <div style={{color: "red"}}>{errors.wallet.message}</div>}
                </div>
                <div className={style.row}>
                    <span>chain_id</span>
                    <input className={style.normale} placeholder='my-test-chain' {...register("network", {                        
                    })} onInput={inputHandler} value={mainState.network}/>
                    {errors.network && <div style={{color: "red"}}>{errors.network.message}</div>}
                </div>
                <div className={style.row}>
                    <span>denome</span>
                    <input className={style.prun} placeholder='usim' {...register("denom", {                        
                    })} onInput={inputHandler} value={mainState.denom}/>
                    {errors.denom && <div style={{color: "red"}}>{errors.denom.message}</div>}
                </div>
                <div className={style.row}>
                    <span>fees</span>
                    <input className={style.prun} placeholder='0' {...register("fees", {                                     
                    })} onInput={inputHandler} value={mainState.fees}/>
                    {errors.fees && <div style={{color: "red"}}>{errors.fees.message}</div>}
                </div>
                <div className={style.row}>
                    <span>prunning</span>
                    <input className={style.prun} placeholder='100' {...register("pruning1", {                         
                    })} onInput={inputHandlerNumbers} value={mainState.pruning1} name="pruning1"/>
                    <input className={style.prun} placeholder='0'  {...register("pruning2", {
                    })} onInput={inputHandlerNumbers} value={mainState.pruning2} name="pruning2"/>
                    <input className={style.prun} placeholder='10'  {...register("pruning3", {
                    })} onInput={inputHandlerNumbers} value={mainState.pruning3} name="pruning3"/>
                    {errors.pruning1 && <div style={{color: "red"}}>{errors.pruning1.message}</div> ||
                    errors.pruning2 && <div style={{color: "red"}}>{errors.pruning2.message}</div> ||
                    errors.pruning3 && <div style={{color: "red"}}>{errors.pruning3.message}</div>
                    }
                </div>                
                <div className={style.row}>
                    <span className={style.row_elem}>peers</span>
                    <textarea className={style.seeds_peers} {...register("peers", {
                    })} onInput={inputHandlerReg} value={mainState.peers}/>
                    {errors.peers && <div style={{color: "red"}}>{errors.peers.message}</div>}
                </div>
                <div className={style.row}>
                    <span className={style.row_elem}>seeds</span>
                    <textarea className={style.seeds_peers} {...register("seeds", {
                    })} onInput={inputHandlerReg} value={mainState.seeds}/>
                    {errors.seeds && <div style={{color: "red"}}>{errors.seeds.message}</div>}
                </div>
                <div className={style.row}>    
                <span>node_number</span>
                <button onClick={n0} className={style.normale4}>1</button>
                <button onClick={n1} className={style.normale4}>2</button>
                <button onClick={n2} className={style.normale4}>3</button>              
                <button onClick={n3} className={style.normale4}>4</button>
                <button onClick={n4} className={style.normale4}>5</button>              
                <button onClick={n5} className={style.normale4}>6</button>
                <button onClick={n6} className={style.normale4}>7</button>              
                <button onClick={n7} className={style.normale4}>8</button>
                <button onClick={n8} className={style.normale4}>9</button>                  
                <button onClick={n9} className={style.normale4}>10</button>                           
                <button onClick={n10} className={style.normale4}>11</button>                  
                <button onClick={n11} className={style.normale4}>12</button>                  
                <button onClick={n12} className={style.normale4}>13</button>                  
                <button onClick={n13} className={style.normale4}>14</button>                  
                <button onClick={n14} className={style.normale4}>15</button>                  
                <button onClick={n15} className={style.normale4}>16</button>                  
                <button onClick={n16} className={style.normale4}>17</button>                  
                <button onClick={n17} className={style.normale4}>18</button>                  
                <button onClick={n18} className={style.normale4}>19</button>                  
                </div>
                <div className={style.row}><span>app.toml</span> 1317
                    <input className={style.smallImp} {...register("a1", {
                    })} onInput={inputHandlerNumbers} value={mainState.a1}/>, 8080
                    <input className={style.smallImp}  {...register("a2", {
                    })} onInput={inputHandlerNumbers} value={mainState.a2}/>, 9090
                    <input className={style.smallImp} {...register("a3", {
                    })} onInput={inputHandlerNumbers} value={mainState.a3}/>, 9091
                    <input className={style.smallImp} {...register("a4", {
                    })} onInput={inputHandlerNumbers} value={mainState.a4}/></div>
                {errors.a1 && <div style={{color: "red"}}>{errors.a1.message}</div> ||
                errors.a2 && <div style={{color: "red"}}>{errors.a2.message}</div> ||
                errors.a3 && <div style={{color: "red"}}>{errors.a3.message}</div> ||
                errors.a4 && <div style={{color: "red"}}>{errors.a4.message}</div>
                }

                <div className={style.row}><span>config.toml</span> 26658
                    <input className={style.smallImp} {...register("c1", {
                    })} onInput={inputHandlerNumbers} value={mainState.c1}/>, 26657*
                    <input className={style.smallImp} {...register("c2", {
                    })} onInput={inputHandlerNumbers} value={mainState.c2}/>, 6060
                    <input className={style.smallImp} {...register("c3", {
                    })} onInput={inputHandlerNumbers} value={mainState.c3}/>, 26656

                    <input className={style.smallImp} {...register("c4", {
                    })} onInput={inputHandlerNumbers} value={mainState.c4}/>, 26660
                    <input className={style.smallImp} {...register("c5", {
                    })} onInput={inputHandlerNumbers} value={mainState.c5}/>
                    {errors.c1 && <div style={{color: "red"}}>{errors.c1.message}</div> ||
                    errors.c2 && <div style={{color: "red"}}>{errors.c2.message}</div> ||
                    errors.c3 && <div style={{color: "red"}}>{errors.c3.message}</div> ||
                    errors.c4 && <div style={{color: "red"}}>{errors.c4.message}</div> ||
                    errors.c5 && <div style={{color: "red"}}>{errors.c5.message}</div>
                    }
                </div>
                <div className={style.row}>
                    <span title="your valoper address">val_address</span>
                    <input placeholder='valoper...' className={style.normale} {...register("valoper", {
                    })} onInput={inputHandler} value={mainState.valoper}/>
                    {errors.valoper && <div style={{color: "red"}}>{errors.valoper.message}</div>}
                </div>
                <div className={style.row}>
                    <span>vote_number</span>
                    <input className={style.prun} placeholder='1' {...register("vote", {
                    })} onInput={inputHandlerNumbers} value={mainState.vote}/>
                    {errors.fees && <div style={{color: "red"}}>{errors.fees.message}</div>}
                </div>
                <div className={style.row}>
                    <span title="for delegate or unbond or send tokens">amount</span>
                    <input className={style.prun} placeholder='1' {...register("amount", {
                    })} onInput={inputHandlerNumbers} value={mainState.amount}/>
                    {errors.fees && <div style={{color: "red"}}>{errors.fees.message}</div>}
                </div>
            </form>

            <span className={style.tochange}>!!! you need to replace the information highlighted in this color with your own !!!</span>
            <div className={"result"}>
                <div className={style.comment}>initialize node</div>
                <div className={style.command}>{mainState.binary} init {mainState.name} --chain-id {mainState.network}</div>
                <div className={style.comment}>configure chain</div>
                <div className={style.command}>{mainState.binary} config chain-id {mainState.network}</div>
                
                {mainState.c2 && <><div className={style.comment}>configure port</div>
                <div className={style.command}>{mainState.binary} config node tcp://localhost:{mainState.c2}</div></>
                }       

                <div className={style.comment}>create wallet</div>
                <div className={style.command}>{mainState.binary} keys add {mainState.wallet}</div>
                <div className={style.comment}>recover wallet</div>
                <div className={style.command}>{mainState.binary} keys add {mainState.wallet} --recover</div>
                <div className={style.comment}>list of wallets</div>
                <div className={style.command}>{mainState.binary} keys list</div>
                <div className={style.comment}>delete of wallet</div>
                <div className={style.command}>{mainState.binary} keys delete {mainState.wallet}</div>
                <div className={style.comment}>set minimum gas prices</div>
                <div className={style.command}>sed -i.bak -e "s/^minimum-gas-prices *=.*/minimum-gas-prices = \"<i
                    className={style.tochange}>0</i>{mainState.denom}\"/;" $HOME/.{mainState.dir}/config/app.toml
                </div>
                <div className={style.comment}>set prunning (one command)</div>
                <div className={style.command}>
                    <div>pruning="custom" && \</div>
                    <div>pruning_keep_recent={mainState.pruning1} && \</div>
                    <div>pruning_keep_every={mainState.pruning2} && \</div>
                    <div>pruning_interval={mainState.pruning3} && \</div>
                    <div>sed -i -e "s/^pruning *=.*/pruning = \"$pruning\"/" $HOME/.{mainState.dir}/config/app.toml &&
                        \
                    </div>
                    <div>sed -i -e "s/^pruning-keep-recent *=.*/pruning-keep-recent = \"$pruning_keep_recent\"/"
                        $HOME/.{mainState.dir}/config/app.toml && \
                    </div>
                    <div>sed -i -e "s/^pruning-keep-every *=.*/pruning-keep-every = \"$pruning_keep_every\"/"
                        $HOME/.{mainState.dir}/config/app.toml && \
                    </div>
                    <div>sed -i -e "s/^pruning-interval *=.*/pruning-interval = \"$pruning_interval\"/"
                        $HOME/.{mainState.dir}/config/app.toml
                    </div>
                </div>
                <div className={style.comment}>add peers/seeds</div>
                <div className={style.command}>sed -i.bak -e "s/^persistent_peers *=.*/persistent_peers =
                    \"{mainState.peers}\"/; s/^seeds *=.*/seeds = \"{mainState.seeds}\"/" $HOME/.{mainState.dir}/config/config.toml
                </div>
                <div className={style.comment}>off indexer</div>
                <div className={style.command}>
                    <div>indexer="null" && sed -i -e "s/^indexer *=.*/indexer = \"$indexer\"/"
                        $HOME/.{mainState.dir}/config/config.toml
                    </div>
                </div>
                <div className={style.comment}>change ports (one command)</div>
                <div className={style.command}>
                    <div>sed -i.bak -e "s%^proxy_app = \"tcp://127.0.0.1:26658\"%proxy_app =
                        \"tcp://127.0.0.1:{mainState.c1}\"%; s%^laddr = \"tcp://127.0.0.1:26657\"%laddr =
                        \"tcp://127.0.0.1:{mainState.c2}\"%; s%^pprof_laddr = \"localhost:6060\"%pprof_laddr =
                        \"localhost:{mainState.c3}\"%; s%^laddr = \"tcp://0.0.0.0:26656\"%laddr =
                        \"tcp://0.0.0.0:{mainState.c4}\"%; s%^prometheus_listen_addr = \":26660\"%prometheus_listen_addr
                        = \":{mainState.c5}\"%" $HOME/.{mainState.dir}/config/config.toml && \
                    </div>
                    <div>sed -i.bak -e "s%^address = \"tcp://0.0.0.0:1317\"%address = \"tcp://0.0.0.0:{mainState.a1}\"%;
                        s%^address = \":8080\"%address = \":{mainState.a2}\"%; s%^address =
                        \"0.0.0.0:9090\"%address = \"0.0.0.0:{mainState.a3}\"%; s%^address = \"0.0.0.0:9091\"%address =
                        \"0.0.0.0:{mainState.a4}\"%" $HOME/.{mainState.dir}/config/app.toml
                    </div>
                </div>
                <div className={style.comment}>create service (one command)</div>
                <div className={style.command}>
                    <div>sudo tee /etc/systemd/system/{mainState.binary}.service {'> /dev/null <<EOF'}</div>
                    <div>[Unit]</div>
                    <div>Description={mainState.dir}</div>
                    <div>After=network-online.target</div>
                    <br/>
                    <div>[Service]</div>
                    <div>User=$USER</div>
                    <div>ExecStart=$(which {mainState.binary}) start</div>
                    <div>Restart=on-failure</div>
                    <div>RestartSec=3</div>
                    <div>LimitNOFILE=65535</div>
                    <br/>
                    <div>[Install]</div>
                    <div>WantedBy=multi-user.target</div>
                    <div>EOF</div>
                </div>
                <div className={style.comment}>start service (one command = daemon-reload + enable + restart + logs)</div>
                <div className={style.command}>
                    <div>sudo systemctl daemon-reload && sudo systemctl enable {mainState.binary} && sudo systemctl
                        restart {mainState.binary} && sudo journalctl -u {mainState.binary} -f -o cat
                    </div>
                </div>
                <div className={style.comment}>create validator (one command)</div>
                <div className={style.command}>
                    <div>{mainState.binary} tx staking create-validator \</div>
                    <div>--chain-id="{mainState.network}" \</div>
                    <div>--commission-rate=0.1 \</div>
                    <div>--commission-max-rate=0.2 \</div>
                    <div>--commission-max-change-rate=0.05 \</div>
                    <div>--min-self-delegation="1" \</div>
                    <div>--amount=1000000{mainState.denom} \</div>
                    <div>--pubkey $({mainState.binary} tendermint show-validator) \</div>
                    <div>--moniker="{mainState.moniker}" \</div>
                    <div>--identity="{mainState.identity}" \</div>
                    <div>--details="{mainState.details}" \</div>
                    <div>--website="{mainState.website}" \</div>
                    <div>--from="{mainState.wallet}" \</div>
                    <div>--gas="auto" \</div>
                    <div>--fees {mainState.fees}{mainState.denom} {mainState.c2 && `--node tcp://127.0.0.1:${mainState.c2}`}</div>
                </div>
                <div className={style.comment}>edit validator (one command)</div>
                <div className={style.command}>
                    <div>{mainState.binary} tx staking edit-validator \</div>
                    <div>--identity="{mainState.identity}" \</div>
                    <div>--details="{mainState.details}" \</div>
                    <div>--website="{mainState.website}" \</div>
                    <div>--from="{mainState.wallet}" \</div>
                    <div>--gas="auto" \</div>
                    <div>--fees {mainState.fees}{mainState.denom} {mainState.c2 && `--node tcp://127.0.0.1:${mainState.c2}`}</div>
                </div>
                <div className={style.comment}>check balance</div>
                <div className={style.command}>{mainState.binary} query bank balances $({mainState.binary} keys
                    show {mainState.wallet} --address) {mainState.c2 && `--node tcp://127.0.0.1:${mainState.c2}`}</div>
                <div className={style.comment}>get your valoper address</div>
                <div className={style.command}>{mainState.binary} keys show {mainState.wallet} --bech val -a</div>
                <div className={style.comment}>check validator</div>
                <div className={style.command}>{mainState.binary} query staking
                    validator {mainState.valoper} {mainState.c2 && `--node tcp://127.0.0.1:${mainState.c2}`}</div>
                <div className={style.comment}>get rewards & comissions from your validator</div>
                <div className={style.command}>{mainState.binary} tx distribution
                    withdraw-rewards {mainState.valoper} --from {mainState.wallet} --chain-id {mainState.network} --fees {mainState.fees}{mainState.denom} --commission {mainState.c2 && `--node tcp://127.0.0.1:${mainState.c2}`} -y
                </div>
                <div className={style.comment}>get rewards from another validator(s)</div>
                <div className={style.command}>{mainState.binary} tx distribution withdraw-all-rewards --from {mainState.wallet} --chain-id {mainState.network} --fees {mainState.fees}{mainState.denom} {mainState.c2 && `--node tcp://127.0.0.1:${mainState.c2}`} -y</div>
                <div className={style.comment}>delegate</div>
                <div className={style.command}>{mainState.binary} tx staking
                    delegate {mainState.valoper} {mainState.amount}{mainState.denom} --from {mainState.wallet} --fees {mainState.fees}{mainState.denom} --chain-id {mainState.network} {mainState.c2 && `--node tcp://127.0.0.1:${mainState.c2}`} -y
                </div>
                <div className={style.comment}>redelegate (from your validator to another)</div>
                <div className={style.command}>{mainState.binary} tx staking redelegate {mainState.valoper} <i
                    className={style.tochange}>valoperaddress...</i> {mainState.amount}{mainState.denom} --from {mainState.wallet} --chain-id {mainState.network} --fees {mainState.fees}{mainState.denom} {mainState.c2 && `--node tcp://127.0.0.1:${mainState.c2}`} -y
                </div>
                <div className={style.comment}>unbond</div>
                <div className={style.command}>{mainState.binary} tx staking unbond {mainState.valoper} {mainState.amount}{mainState.denom} --from {mainState.wallet} --fees {mainState.fees}{mainState.denom} --chain-id {mainState.network} {mainState.c2 && `--node tcp://127.0.0.1:${mainState.c2}`} -y
                </div>
                <div className={style.comment}>send tokent to another address</div>
                <div className={style.command}>{mainState.binary} tx bank send {mainState.wallet} <i
                    className={style.tochange}>address...</i> {mainState.amount}{mainState.denom} --from {mainState.wallet} --fees {mainState.fees}{mainState.denom} --chain-id {mainState.network} {mainState.c2 && `--node tcp://127.0.0.1:${mainState.c2}`} -y
                </div>
                <div className={style.comment}>unjail</div>
                <div className={style.command}>{mainState.binary} tx slashing unjail --from {mainState.wallet} --chain-id {mainState.network} {mainState.c2 && `--node tcp://127.0.0.1:${mainState.c2}`} -y</div>
                <div className={style.comment}>vote</div>
                <div className={style.command}>{mainState.binary} tx gov vote {mainState.vote} yes --from {mainState.wallet} --chain-id {mainState.network} --fees {mainState.fees}{mainState.denom} {mainState.c2 && `--node tcp://127.0.0.1:${mainState.c2}`} -y</div>
                <div className={style.comment}>param network</div>
                <div className={style.command}>{mainState.binary} q staking params</div>
                <div className={style.comment}>list of active validators (for inactive change to BOND_STATUS_UNBONDED)
                </div>
                <div className={style.command}>{mainState.binary} q staking validators
                    -oj {mainState.c2 && `--node tcp://127.0.0.1:${mainState.c2}`} --limit=1000 | jq '.validators[] |
                    select(.status=="BOND_STATUS_BONDED")' | jq -r
                    '(.tokens|tonumber/pow(10; 6)|floor|tostring) + " \t
                    " + .description.moniker' | sort -gr | nl
                </div>
                <div className={style.comment}>start node</div>
                <div className={style.command}>sudo systemctl start {mainState.binary}</div>
                <div className={style.comment}>stop node</div>
                <div className={style.command}>sudo systemctl stop {mainState.binary}</div>
                <div className={style.comment}>restart node</div>
                <div className={style.command}>sudo systemctl restart {mainState.binary}</div>
                <div className={style.comment}>check logs</div>
                <div className={style.command}>sudo journalctl -u {mainState.binary} -f -o cat</div>
            </div>
        </div>
    );
});


