import React from 'react';
import './App.css';
import {Main} from "./components/main/Main";
import { Info } from './components/info/Info';


function App() {
    return (
        <div className="App">
            {/*<Main/>*/}

            <Info/>
        </div>
    );
}

export default App;
